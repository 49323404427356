/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, debounce, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { LoadingButton } from "@mui/lab";

import Header from "../../components/Header";
import Disclaimer from "../../components/common/Disclaimer";
import CustomChip from "../../components/common/CustomChip";
import CustomButtonGroup from "../../components/common/CustomButtonGroup";
import CustomCard from "../../components/common/BasicCustomCard";
import LeverageTable from "../../components/LeverageTable";
import BridgeLoanResultTab from "../../components/common/Loans/BridgeLoanResultTab";
import CustomModal from "../../components/common/CustomModal";

import { RootContext } from "../../context/RootContext";
import { LoansContext } from "../../context/LoansContext";
import CheckedCircle from "../../assets/CheckedCircle.svg";
import InfoErrorIcon from "../../assets/InfoErrorIcon.svg";
import InfoMsgIcon from "../../assets/InfoMsgIcon.svg";
import CloseIcon from "../../assets/Close-Icon-1.svg";
import WarningIcon from "../../assets/WarningIcon.svg";
import AlertTriangle from "../../assets/AlertTriangle.svg";
import ShareIcon from "../../assets/ShareIcon.svg";
import WarnigIconYellow from "../../assets/WarningIconYellow.svg";
import ExpandButton from "../../assets/ExpandButton.svg";
import ShrinkButton from "../../assets/ShrinkButton.svg";

import {
  exportHTML,
  getCookie,
  getFormattedDate,
  normalizeSubmitApplicationLoanData,
  normalizeSubmitApplicationPropertyData,
  rehabTypes,
} from "../../utils/Common";

import { TabPanelProps, ZipCodeSuggestion } from "../../types/fieldTypes";
import { ObjectType } from "../../types/tableTypes";

import {
  getAutocompleteAddress,
  loanDataBasedOnLoanId,
  postCalculateEligibility,
  postCreateLoan,
  postUpdateLoanUrl,
  postUpdatePropertyUrl,
  patchSaveApplication,
  getZipCodeList,
  fetchTemplateLabel,
} from "../../services/network";

import { getTermSheetVersions } from "../../services/termSheet";

import {
  checkMinDataFilled,
  fetchGuidelineEvalResult,
  handleCondoEligibilityModalChange,
  postGuidelineEvaluation,
  renderFieldUI,
  sampleCondoEligibility,
} from "./Common";

import { TabLists, getCondoEligibilityFields, getConfig } from "./LoanFields";
import { getLeveragePricingTestsConfig } from "./LeveragePricing";
import LoanDetails from "./LoanDetails";
import PropertyDetails from "./PropertyDetails";

import { errorSnackBar, snackbarStyle } from "./style";
import { templateKeys } from "../../utils/utility";
import AutoCalculatedFields from "./AutoCalculatedFields";
import BorrowerInformation from "./BorrowerInformation";
import ExpandedFieldsUI from "./ExpandedFieldsUI";

import "./Loans.css";
import PartnerSelectionView from "./PartnerAndProgrammeSelectionWrapper";
import TermSheetBanner from "../../components/termSheet/TermSheetBanner";

type ParamType = {
  loanType: string | undefined;
  loanId: string | undefined;
  propType: string | undefined;
};

export default function LoansPage() {
  const params = useParams<ParamType>();

  const RootContextData = useContext(RootContext);
  const loanContextData = useContext(LoansContext);

  const {
    decodedTokenData,
    userPermissions,
    setDynamicLoader,
    dynamicConfig,
    templateLabels,
    setTemplateLabels,
    DSCROrgPartnerMapping,
    setTermSheetVersions,
    showTermSheet,
    setCurrentPath,
    showKeyDealMetrics,
    setShowKeyDealMetrics
  } = RootContextData;
  const {
    chipsData,
    loanDetailsConfig,
    propertyDetailsConfig,
    borrowerInformationConfig,
    loanDetails,
    propertyDetails,
    isApplicationSubmitted,
    showSnackBar,
    expandLeverageAndTests,
    defaultSelectedDataView,
    leverageTestsConfig,
    loanFormErrors,
    propFormErrors,
    leverageAndPricingData,
    testTabData,
    autoCalculatedLoanResults,
    autoCalculatedPropResults,
    condoEligibilityConfig,
    condoEligibility,
    condoEligibilityFormData,
    addressInputValue,
    addressOptions,
    zipCodeInputValue,
    failedFinalRate,
    fetchData,
    ratesAsOfDate,
    debouncedValue,
    noAssignedPartner,
    assignedPartner,
    expandAvailableProgramsView,
    rulesResponse,
    templateResponseData,
    eligiblePartnerResults,
    partnersResults,
    loansApiResponse,
    // setState functions
    setChipsData,
    setAutoCalculatedFields,
    setLoanDetailsConfig,
    setBorrowerInformationConfig,
    setPropertyDetailsConfig,
    setLoanDetails,
    setPropertyDetails,
    setIsApplicationSubmitted,
    setShowSnackBar,
    setExpandLeverageAndTests,
    setDefaultSelectedDataView,
    setLeverageTestsConfig,
    setLoanFormErrors,
    setPropFormErrors,
    setLeverageAndPricingData,
    setPropertyDetailsTemplate,
    setPropertyDetailsConfigTemplate,
    setUnitInfoConfigTemplate,
    setUnitInfoTemplate,
    setTestTabData,
    setAutoCalculatedLoanResults,
    setAutoCalculatedPropResults,
    setLoansApiResponse,
    setCondoEligibilityConfig,
    setCondoEligibility,
    setCondoEligibilityFormData,
    setAddressOptions,
    setZipCodeOptions,
    setFetchData,
    setIsUnitExpanded,
    setDebouncedValue,
    setAssignedPartner,
    setExpandAvailableProgramsView,
    setRulesResponse,
    setTemplateResponseData,
    setEligiblePartnerResults,
    setPartnersResults
  } = loanContextData;

  const { t } = useTranslation();
  const theme = useTheme();

  const [currentConfig, setCurrentConfig] = useState<ObjectType>({});
  const [isExpanded, setIsExpanded] = useState<ObjectType>({});
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicationName, setApplicationName] = useState<string>("");
  const [applicationError, setApplicationError] = useState<string>("");
  const [openSaveApplicationModal, setOpenSaveApplicationModal] =
    useState<boolean>(false);
  const [showLoanDetails, setShowLoanDetails] = useState<boolean>(false);
  const [evaluationID, setEvaluationID] = useState<number>();

  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [disableSubmitApplication, setDisableSubmitApplication] =
    useState<boolean>(true);
  const [tcLoanId, setTcLoanId] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const [openWarningBeforeSubmit, setOpenWarningBeforeSubmit] = useState<boolean>(false);
  const [showTermSheetBanner, setShowTermSheetBanner] = useState<boolean>(false)

  const { loanType, loanId, propType } = params;
  const orgFundingType = getCookie("orgFundingType");
  const latData = getCookie("latData")

  const realmHeaders = {
    "kc-realm": dynamicConfig?.ViteAppKcRealm,
  };

  const assignedOrTakeOutPartnerId = assignedPartner || loanDetails?.takeOutPartner || "";

  useEffect(() => {
    setShowTermSheetBanner(!!showTermSheet)
  },[showTermSheet])

  useEffect(() => {
    if (!dynamicConfig) return;
    setDynamicLoader(true);
    fetchTemplateLabel(templateKeys, dynamicConfig.clientId)
      .then((templateResponse) => {
        const templteResult: any = {};

        Object.keys(templateResponse?.data ?? {}).forEach((res) => {
          templteResult[res] = templateResponse?.data?.[res].replace(/`/g, "");
        });
        setTemplateLabels(templteResult);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDynamicLoader(false);
      });
  }, [dynamicConfig]);

  useEffect(() => {
    setCurrentPath(window.location.pathname)
    getTermSheetVersionsForLoan();
    const tempCondoEligibiltyConfig: ObjectType = getCondoEligibilityFields();
    setCondoEligibilityConfig(tempCondoEligibiltyConfig);
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const confirmationMessage = t("reloadText");
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    setExpandAvailableProgramsView(false)

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isApplicationSubmitted) {
        fetchGuidelineEvalResult(
          loanId,
          loanType,
          propType,
          loanContextData,
          RootContextData,
          templateResponseData,
          setTemplateResponseData,
          dynamicConfig,
          templateLabels
        );
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedValue, dynamicConfig]);

  useEffect(() => {
    getLoanDataBasedOnLoanId();
  }, [loanId, templateLabels]);

  useEffect(() => {
    let active: boolean = true;
    if (addressInputValue === "") {
      return undefined;
    }
    fetchAddress({ input: addressInputValue });

    return () => {
      active = false;
    };
  }, [addressInputValue]);

  useEffect(() => {
    let active = true;
    if (zipCodeInputValue.length < 4 || zipCodeInputValue.length > 7) {
      return undefined;
    }
    fetchCityState({ input: zipCodeInputValue });

    return () => {
      active = false;
    };
  }, [zipCodeInputValue]);

  useEffect(() => {
    if (testTabData?.length) {
      const tempIsExpanded: ObjectType = {};
      testTabData.forEach((ele: ObjectType) => {
        const label = Object.keys(ele)[0];
        tempIsExpanded[label] = ele?.isExpanded ?? true;
      });
      setIsExpanded({ ...isExpanded, ...tempIsExpanded });
    }
  }, [testTabData]);

  useEffect(() => {
    if (decodedTokenData) {
      setCondoEligibilityFormData({
        ...condoEligibilityFormData,
        ["borrowerName"]: decodedTokenData?.name,
      });
    }
  }, [decodedTokenData]);

  useEffect(() => {
    const tempPropConfig = propertyDetailsConfig.map(
      (propConfig: ObjectType, propIdx: number) => {
        return {
          ...propConfig,
          fields: propConfig?.fields?.map((field: ObjectType) => {
            if (["propertyInformation"].includes(field?.id)) {
              return {
                ...field,
                fields: field.fields.map((subField: ObjectType) => {
                  if (
                    [
                      "monthlyPropertyGrossRent",
                      "rentalCharacterization",
                    ]?.includes(subField.id)
                  ) {
                    return {
                      ...subField,
                      minimumDataView:
                        defaultSelectedDataView !== "fullDataView",
                      isVisible: defaultSelectedDataView !== "fullDataView",
                    };
                  }
                  return subField;
                }),
              };
            } else if (Array.isArray(field)) {
              return field?.map((unitField: ObjectType, unitIdx: number) => {
                if (
                  Object.keys(propertyDetails[propIdx].unitInformation[unitIdx])
                    ?.length
                ) {
                  const unitFieldArr = unitField.fields?.map(
                    (unit: ObjectType) => {
                      const tempUnitField = { ...unit };
                      if (defaultSelectedDataView === "fullDataView") {
                        const value: number | null = propertyDetails[propIdx]
                          .unitInformation[unitIdx]["currentLeaseTermsInMonths"]
                          ? +propertyDetails[propIdx].unitInformation[unitIdx][
                          "currentLeaseTermsInMonths"
                          ]
                          : null;
                        const isZero = value && value === 0;
                        const isInRange1to5 = value && value >= 1 && value <= 5;
                        const dynamicFields = [
                          "currentUnitUsage",
                          "rentalCharacterization",
                          "monthlyMarketRent",
                          "monthlyUnitRent",
                          "tenantOccupancy",
                          "operatingHistory",
                        ];
                        dynamicFields.forEach((fieldStr) => {
                          if (fieldStr === tempUnitField.label) {
                            if (
                              (isZero &&
                                [
                                  "currentUnitUsage",
                                  "rentalCharacterization",
                                  "monthlyMarketRent",
                                  "monthlyUnitRent",
                                ].includes(fieldStr)) ||
                              (isInRange1to5 &&
                                [
                                  "rentalCharacterization",
                                  "monthlyMarketRent",
                                  "monthlyUnitRent",
                                  "tenantOccupancy",
                                  "operatingHistory",
                                ].includes(fieldStr)) ||
                              (value &&
                                value > 5 &&
                                [
                                  "rentalCharacterization",
                                  "monthlyMarketRent",
                                  "monthlyUnitRent",
                                ].includes(fieldStr))
                            ) {
                              tempUnitField.minimumDataView =
                                defaultSelectedDataView === "fullDataView";
                            } else {
                              tempUnitField.minimumDataView =
                                defaultSelectedDataView !== "fullDataView";
                            }
                            tempUnitField.isVisible =
                              tempUnitField.minimumDataView;
                          }
                        });
                        return tempUnitField;
                      }
                      return {
                        ...tempUnitField,
                        minimumDataView: false,
                        isVisible: true,
                      };
                    }
                  );
                  return {
                    ...unitField,
                    fields: unitFieldArr,
                  };
                }
                const unitFieldArr = unitField.fields?.map(
                  (unit: ObjectType) => {
                    if (
                      ![
                        "currentLeaseTermsInMonths",
                        "inPlaceLeaseRentMonthly",
                        "percentUnitOccupied",
                      ].includes(unit.id)
                    ) {
                      return {
                        ...unit,
                        minimumDataView: false,
                        isVisible: false,
                      };
                    }
                    return unit;
                  }
                );
                return { ...unitField, fields: unitFieldArr };
              });
            }
            return field;
          }),
        };
      }
    );
    const currentLoanType = loanType === "InvestorDSCR" ? "dscr" : "bridge";

    // if(showKeyDealMetrics){
    //   if(loanType === "InvestorDSCR") {
    //     if(defaultSelectedDataView !== "fullDataView") {
    //       setShowKeyDealMetrics(false)
    //     } else {
    //       setShowKeyDealMetrics(true)
    //     }
    //   }
    // }

    setPropertyDetailsConfig(tempPropConfig);
    setDisableSubmitApplication(
      (loanType === "InvestorDSCR"
        ? defaultSelectedDataView !== "fullDataView"
        : false) ||
      !userPermissions.includes(`submit_${currentLoanType}`) ||
      isApplicationSubmitted
    );
    if (loanType === "InvestorDSCR") {
      checkMinDataFilled(
        loanContextData,
        assignedPartner ? "" : "noAssignedPartner",
        loanType,
        fetchData
      );
    }
  }, [defaultSelectedDataView, isApplicationSubmitted, assignedPartner, fetchData]);

  useEffect(() => {
    setDisableSubmitApplication((expandLeverageAndTests && defaultSelectedDataView !== "fullDataView") || isApplicationSubmitted);
  }, [expandLeverageAndTests, defaultSelectedDataView, isApplicationSubmitted]);

  useEffect(() => {
    if (latData) {
      setShowKeyDealMetrics(true)
      if (loanType === "InvestorDSCR") {
        if (defaultSelectedDataView !== "fullDataView") {
          setShowKeyDealMetrics(false)
        } else {
          setShowKeyDealMetrics(true)
        }
      }
    }
  }, [latData, loanType, defaultSelectedDataView])

  useEffect(() => {
    const tempResult: ObjectType = {};
    propertyDetailsConfig?.map((propDetail: ObjectType, propIdx: number) => {
      propDetail?.fields?.map((field: ObjectType) => {
        if (Array.isArray(field)) {
          const unitExpanded: ObjectType = {};
          field?.map((unitField: ObjectType, idx: number) => {
            unitExpanded[`${t("unit")} ${idx + 1}`] = true;
          });
          tempResult[`Property #${propIdx + 1}`] = unitExpanded;
        }
      });
    });
    setIsUnitExpanded(tempResult);
  }, [propertyDetailsConfig]);

  const isRiskPricing = useMemo(() => {
    if (leverageAndPricingData?.leverageCards?.pricingType === "RISK") {
      return "riskBasedPricing";
    }
    if (leverageAndPricingData?.leverageCards?.pricingType === "EXPERIENCE") {
      return "experienceBasedPricing";
    }
    return "";
  }, [leverageAndPricingData]);

  async function getTermSheetVersionsForLoan() {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const { data } = await getTermSheetVersions(realmHeaders, loanId);
      setTermSheetVersions(data?.data?.sort((a: any, b: any) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }));
    } catch (err) {
      console.error(err);
    }
  }

  function handleDataViewChange(button: string) {
    setDefaultSelectedDataView(button);
    postGuidelineEvaluation(setDebouncedValue, button);
  }

  function preLoadCondoEligibleConfig() {
    let tempConfig = { ...condoEligibilityConfig };
    tempConfig = {
      ...tempConfig,
      fields: {
        ...tempConfig?.fields,
        ["fee"]: tempConfig?.fields?.fee?.map((feeEle: any) => {
          if (feeEle?.label === "ownerControlStartDate") {
            if (condoEligibilityFormData?.["hoaUnderOwnerControl"] === "Yes") {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          if (feeEle.label === "indivdualOwnedUnits") {
            if (
              condoEligibilityFormData?.[
              "individualOwnershipAboveTwentyFive"
              ] === "Yes"
            ) {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          if (feeEle.label === "hoawithLitOrBankruptcyDesc") {
            if (
              condoEligibilityFormData?.["hoawithLitOrBankruptcy"] === "Yes"
            ) {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          if (feeEle.label === "restrictOwnerToRentOutDesc") {
            if (condoEligibilityFormData?.["restrictOwnerToRent"] === "Yes") {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          return { ...feeEle };
        }),
      },
    };
    return tempConfig;
  }

  function getHeadingText(text: ObjectType, card: ObjectType) {
    const isProfitable = Boolean(text?.profitability);
    const profitabilityTest = Boolean(testTabData?.find((test: any) => test.title === "Profitability Test" && test.status === "Failed"));
    let returnValue = <span className={isProfitable && profitabilityTest ? "red-text" : ""}>{text?.[card.label]}</span>;

    if (card.label === "finalRate") {
      returnValue = (
        <span>
          {failedFinalRate ? (
            <img src={AlertTriangle} alt="failed.svg" />
          ) : null}
          {text?.[card.label]}
        </span>
      );
    }
    return returnValue;
  }

  const disclaimer = useMemo(() => {
    const showDisclaimer = dynamicConfig?.disclaimer !== "";
    if (!showDisclaimer) return "";
    return (
      <>
        {dynamicConfig?.disclaimer ?? (
          <span>
            {t("loanDisclaimerText")}{" "}
            <a href={dynamicConfig?.ViteAppTcRedirect} target="_blank">
              {t("toorakConnect")}
            </a>
          </span>
        )}
        {dynamicConfig?.rateSheetLink && (
          <IconButton
            onClick={() => window.open(dynamicConfig?.rateSheetLink, "_blank")}
            data-testid="rate-sheet-icon"
          >
            <img src={ShareIcon} alt="share-icon" />
          </IconButton>
        )}
      </>
    );
  }, [dynamicConfig]);

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setCurrentTab(newValue);
    setExpandAvailableProgramsView(newValue === 2 && loanType === "InvestorDSCR")
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function renderRightPanel() {
    // sort and use
    const [tempLPConfigLeft, tempLPConfigRight, tempLpConfigFull] = [1, 2, 3].map((order) =>
      leverageTestsConfig?.filter((ele: ObjectType) => ele?.order === order)
    );
    return (
      <>
        {expandLeverageAndTests ? (
          <Grid container>
            <Grid item xs={expandLeverageAndTests ? 6 : 12} px={2}>
              {tempLPConfigLeft?.map((ele: ObjectType) => {
                return renderGridLayout(ele);
              })}
            </Grid>
            <Grid item xs={expandLeverageAndTests ? 6 : 12} px={2}>
              {tempLPConfigRight?.map((ele: ObjectType) => {
                return renderGridLayout(ele);
              })}
            </Grid>
            <Grid item xs={12} px={2}>
              {tempLpConfigFull?.map((ele: ObjectType) => {
                return renderGridLayout(ele)
              })}
            </Grid>
          </Grid>
        ) : (
          <>
            {leverageTestsConfig?.map((ele: ObjectType) => {
              switch (ele?.type) {
                case "table":
                  return (
                    <Grid container xs={expandLeverageAndTests ? 6 : 12}>
                      <Grid item xs={12} m={2} style={{ margin: "12px" }}>
                        <CustomCard
                          headingText={t(ele.label)}
                          subHeadingText={""}
                          loanDetails={loanDetails}
                          fullView={ele?.fullView ?? false}
                          ratesAsOfDate={ratesAsOfDate}
                          subComponents={
                            <LeverageTable
                              tableHeader={ele?.tableHeader ?? []}
                              tableBody={
                                leverageAndPricingData[ele.label]?.tableBody
                              }
                              label={ele.label}
                              tableClassName={ele?.tableClassName ?? ""}
                            />
                          }
                          gridSpace={4}
                          data={leverageAndPricingData?.[ele.id]}
                          headerClass={ele?.headerClass ?? ""}
                        />
                      </Grid>
                    </Grid>
                  );
                case "disclaimer":
                  return loanDetails?.ratesAsOf || ratesAsOfDate ? (
                    <Grid container xs={expandLeverageAndTests ? 6 : 12}>
                      <Grid item xs={12} m={2}>
                        <Disclaimer
                          boldText={""}
                          children={
                            loanType === "InvestorDSCR" ? (
                              <span>
                                {t("ratesAsOfDate")} &nbsp;
                                <b>
                                  {moment(
                                    isApplicationSubmitted ? loanDetails?.ratesAsOf : (ratesAsOfDate || loanDetails?.ratesAsOf),
                                    "DD-MM-YYYY"
                                  ).format("MMM DD, YYYY")}
                                </b>
                                &nbsp;
                                {t("daysLockPeriod")}
                              </span>
                            ) : (
                              <span>
                                {t("pricingAsOfDate")} &nbsp;
                                <b>
                                  {moment(
                                    loanDetails?.ratesAsOf || ratesAsOfDate,
                                    "DD-MM-YYYY"
                                  ).format("MMM DD, YYYY")}
                                </b>
                              </span>
                            )
                          }
                          customClass="disclaimerBox"
                          showClose={false}
                          isRatesAsOff={true}
                        />
                      </Grid>
                    </Grid>
                  ) : null;
                default:
                  return (
                    <Grid
                      container
                      xs={expandLeverageAndTests ? 6 : 12}
                      px={2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: "12px",
                        marginTop: "12px",
                      }}
                    >
                      {ele?.cards?.filter((card: ObjectType) => !card?.hide?.(leverageAndPricingData))?.map((card: ObjectType) => (
                        <Grid item style={{ flexGrow: "1", maxWidth: "55%" }}>
                          <CustomCard
                            headingText={getHeadingText(
                              leverageAndPricingData?.[ele.id],
                              card
                            )}
                            subHeadingText={t(card.subText)}
                            loanDetails={loanDetails}
                            fullView={ele?.fullView ?? false}
                            ratesAsOfDate={ratesAsOfDate}
                            data={leverageAndPricingData?.[ele.id]}
                            gridSpace={card?.gridSpace ?? 4}
                            headerClass={ele?.headerClass ?? ""}
                            showFailed={false}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  );
              }
            })}
          </>
        )}
      </>
    );
  }

  function renderGridLayout(gridEle: any) {
    switch (gridEle?.type) {
      case "table":
        return (
          <div style={{ margin: "15px 0" }}>
            <CustomCard
              headingText={t(gridEle.label)}
              subHeadingText={""}
              loanDetails={loanDetails}
              fullView={gridEle?.fullView ?? false}
              ratesAsOfDate={ratesAsOfDate}
              subComponents={
                <LeverageTable
                  tableHeader={gridEle?.tableHeader ?? []}
                  tableBody={leverageAndPricingData[gridEle.label]?.tableBody}
                  label={gridEle.label}
                  tableClassName={gridEle?.tableClassName ?? ""}
                />
              }
              gridSpace={4}
              data={leverageAndPricingData?.[gridEle.id]}
              headerClass={gridEle?.headerClass ?? ""}
            />
          </div>
        );
      case "disclaimer":
        return loanDetails?.ratesAsOf || ratesAsOfDate ? (
          <Grid container xs={12}>
            <Grid item xs={12} m={expandLeverageAndTests ? 0 : 2}>
              <Disclaimer
                boldText={""}
                children={
                  loanType === "InvestorDSCR" ? (
                    <span>
                      {t("ratesAsOfDate")} &nbsp;
                      <b>
                        {moment(
                          loanDetails?.ratesAsOf || ratesAsOfDate,
                          "DD-MM-YYYY"
                        ).format("MMM DD, YYYY")}
                      </b>
                      &nbsp;
                      {t("daysLockPeriod")}
                    </span>
                  ) : (
                    <span>
                      {t("pricingAsOfDate")} &nbsp;
                      <b>
                        {moment(
                          loanDetails?.ratesAsOf || ratesAsOfDate,
                          "DD-MM-YYYY"
                        ).format("MMM DD, YYYY")}
                      </b>
                    </span>
                  )
                }
                customClass="disclaimerBox"
                showClose={false}
              />
            </Grid>
          </Grid>
        ) : null;
      default:
        return (
          <Grid
            container
            my={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {gridEle?.cards?.filter((card: ObjectType) => !card?.hide?.(leverageAndPricingData))?.map((card: any) => (
              <Grid item style={{ flexGrow: "1", maxWidth: "55%" }}>
                <CustomCard
                  headingText={getHeadingText(
                    leverageAndPricingData?.[gridEle.id],
                    card
                  )}
                  subHeadingText={t(card.subText)}
                  loanDetails={loanDetails}
                  fullView={gridEle?.fullView ?? false}
                  ratesAsOfDate={ratesAsOfDate}
                  data={leverageAndPricingData?.[gridEle.id]}
                  gridSpace={card?.gridSpace ?? 4}
                  headerClass={gridEle?.headerClass ?? ""}
                />
              </Grid>
            ))}
          </Grid>
        );
    }
  }

  function validateForm() {
    let isError: boolean = false;

    let tempFormErrors: ObjectType = { ...loanFormErrors };
    const tempPropFormErrors: ObjectType[] = [...propFormErrors];

    loanDetailsConfig?.fields?.map((subField: ObjectType) => {
      subField?.fields?.map((field: ObjectType) => {
        if (
          field.required &&
          field?.isVisible &&
          !field?.isAutoCalculated(loanType, defaultSelectedDataView)
        ) {
          const error = field.getFieldErrors(
            loanDetails?.[subField?.label]?.[field.label]
          );

          if (error) {
            isError = true;
            tempFormErrors = {
              ...tempFormErrors,
              [subField?.label]: {
                ...tempFormErrors[subField?.label],
                [field.label]: t(error),
              },
            };
          }
        }
      });
    });

    borrowerInformationConfig?.fields?.map((subField: ObjectType) => {
      subField?.fields?.map((field: ObjectType) => {
        if (
          field.required &&
          field?.isVisible &&
          !field?.isAutoCalculated(loanType, defaultSelectedDataView)
        ) {
          const error = field.getFieldErrors(
            loanDetails?.[subField?.label]?.[field.label]
          );

          if (error) {
            isError = true;
            tempFormErrors = {
              ...tempFormErrors,
              [subField?.label]: {
                ...tempFormErrors[subField?.label],
                [field.label]: t(error),
              },
            };
          }
        }
      });
    });

    propertyDetailsConfig?.map((propConfig: ObjectType, parentIdx: number) => {
      let tempPropFormError: any = tempPropFormErrors[parentIdx] || {};
      propConfig?.fields?.map((propSubField: ObjectType) => {
        if (!Array.isArray(propSubField)) {
          propSubField?.fields?.map((field: ObjectType) => {
            if (
              field.required &&
              field?.isVisible &&
              !field?.isAutoCalculated(loanType, defaultSelectedDataView)
            ) {
              const error = field.getFieldErrors(
                propertyDetails[parentIdx]?.[propSubField.label]?.[field.label]
              );
              if (error) {
                isError = true;
                tempPropFormError = {
                  ...tempPropFormError,
                  [propSubField?.label]: {
                    ...tempPropFormError[propSubField?.label],
                    [field.label]: t(error),
                  },
                };
              }
            }
          });
        } else {
          const unitInformations: ObjectType[] = [];
          propSubField?.map(
            (unitParentField: ObjectType, unitParentIdx: number) => {
              unitParentField?.fields?.map((unitField: ObjectType) => {
                if (
                  unitField.required &&
                  unitField?.isVisible &&
                  !unitField?.isAutoCalculated(
                    loanType,
                    defaultSelectedDataView
                  )
                ) {
                  const error = unitField.getFieldErrors(
                    propertyDetails[parentIdx]?.[unitParentField.label][
                    unitParentIdx
                    ]?.[unitField.label]
                  );
                  if (error) {
                    isError = true;
                    unitInformations[unitParentIdx] = {
                      ...unitInformations[unitParentIdx],
                      [unitField.label]: t(error),
                    };
                  }
                }
              });
              tempPropFormError = {
                ...tempPropFormError,
                [unitParentField?.label]: [...unitInformations],
              };
            }
          );
        }
      });
      tempPropFormErrors[parentIdx] = tempPropFormError;
    });

    setLoanFormErrors(tempFormErrors);
    setPropFormErrors(tempPropFormErrors);
    setIsError(isError);
    return isError;
  }

  async function handleSaveApplication(
    isApplicationSubmit?: boolean,
    tempTcLoanId?: string
  ) {
    const isSubmittedApp = isApplicationSubmit || false;
    const tempLoanId = tempTcLoanId || tcLoanId || "";
    if (!applicationName && !isSubmittedApp) {
      setApplicationError(t("thisFieldIsRequired"));
      return;
    }
    setIsLoading(true);
    try {
      // const url = getURL("online-sizer/update");

      const tempData: ObjectType = {
        ...loanDetails,
        orgId: fetchData?.orgId || getCookie("orgId") || dynamicConfig?.clientId,
        fundingType: orgFundingType,
        propertyDetails,
        applicationName: applicationName || evaluationID?.toString(),
        evaluationID,
        isApplicationSubmitted: isSubmittedApp,
        testTabData,
        ratesAsOf: ratesAsOfDate,
        leverageAndPricingData,
        autoCalculatedLoanResults,
        autoCalculatedPropResults: [...autoCalculatedPropResults],
        tcLoanId: tempLoanId,
        takeOutPartner: assignedPartner,
        rulesResponse,
        eligiblePartnerResults,
        partnersResults
      };

      const res = await patchSaveApplication(loanId, tempData);
      setOpenSaveApplicationModal(false);
      setShowLoanDetails(true);
      if (res?.data) {
        if (!isSubmittedApp) {
          setSnackBarMsg("changesSaved");
          setShowSnackBar(true);
        }
        const body = [loanId];
        const { data } = await loanDataBasedOnLoanId(body);
        setLoansApiResponse(data?.data["0"]);
      } else {
        setShowSnackBar(false);
        setSnackBarMsg("changesFailedSave");
      }
      setIsApplicationSubmitted(isSubmittedApp);
    } catch (err) {
      console.error(err);
      setShowSnackBar(false);
      setSnackBarMsg("changesFailedSave");
    } finally {
      setIsLoading(false);
    }
  }

  const handlePromptSubmit = () => {
    setOpenWarningBeforeSubmit(true);
    validateForm();
  }
  async function handleSubmitApplication() {
    setIsLoading(true);
    try {
      // public api changes
      // const updateLoanURL = getURL("public-api/aggregate/loans");

      const loanTypeId = loanType === "InvestorDSCR" ? 1 : 2;
      const orgId = getCookie("orgId") || dynamicConfig?.clientId;

      const partyId = getCookie("partyId") || dynamicConfig?.partyId;
      const createPayload = {
        loanTypeId,
        originatorPartyId: orgId || "",
        loanType: loanType === "InvestorDSCR" ? "30 Year Loan" : "Bridge Loan",
        stage: "FES",
        loanOrigin: "Online Sizer",
        fundingType: "normal",
        servicerPartyId: "",
        servicerName: "BSI",
      };

      const createResult: any = await postCreateLoan(
        createPayload,
        realmHeaders
      );

      if (createResult) {
        const updatePayload = normalizeSubmitApplicationLoanData(
          loanDetails,
          createResult?.data?.loanId,
          loanTypeId,
          condoEligibilityFormData,
          autoCalculatedLoanResults,
          fetchData
        );
        const propertyPayload = normalizeSubmitApplicationPropertyData(
          propertyDetails,
          loanDetails,
          createResult?.data?.loanId,
          loanTypeId,
          autoCalculatedPropResults
        );

        // const updatePropertyURL = getURL(
        //   `public-api/aggregate/loan_property/${createResult?.data?.loanId}/properties?stage=FES`
        // );

        // need to ask
        await postUpdateLoanUrl(updatePayload, realmHeaders);
        await postUpdatePropertyUrl(
          createResult?.data?.loanId,
          propertyPayload,
          realmHeaders
        );

        setTcLoanId(createResult?.data?.loanId);
        setShowSnackBar(true);
        setSnackBarMsg("loanSaved");
        await handleSaveApplication(true, createResult?.data?.loanId);
      } else {
        setShowSnackBar(true);
        setSnackBarMsg("loanSavedFailed");
      }
    } catch (err) {
      console.error(err);
      setShowSnackBar(true);
      setSnackBarMsg("loanSavedFailed");
    } finally {
      setIsLoading(false);
      setOpenWarningBeforeSubmit(false);
    }
  }

  async function calculateEligibility() {
    try {
      const reqBody = {
        data: {
          loan: {
            loanCondominium: {
              monthlyHOAFee: condoEligibilityFormData?.monthlyHOAFee ?? null,
              hoaDuesAboveFifteen:
                condoEligibilityFormData?.hoaDuesAboveFifteen ?? null,
              hoaDuesAboveTwenty:
                condoEligibilityFormData?.hoaDuesAboveTwenty ?? null,
              reserveFundsForOneYear:
                condoEligibilityFormData?.reserveFundsForOneYear ?? null,
              reserveFundsForTwoYears:
                condoEligibilityFormData?.reserveFundsForTwoYears ?? null,
              isProjectCompleted:
                condoEligibilityFormData?.isProjectCompleted ?? null,
              subjectToAdditionalPhasing:
                condoEligibilityFormData?.subjectToAdditionalPhasing ?? null,
              soldPercentage: condoEligibilityFormData?.soldPercentage ?? null,
              hoaUnderOwnerControl:
                condoEligibilityFormData?.hoaUnderOwnerControl ?? null,
              simpleEstateOwnership:
                condoEligibilityFormData?.simpleEstateOwnership ?? null,
              ownerOccupiedUnits:
                condoEligibilityFormData?.ownerOccupiedUnits ?? null,
              renterOccupiedUnits:
                condoEligibilityFormData?.renterOccupiedUnits ?? null,
              renterOccupiedPercentage:
                condoEligibilityFormData?.renterOccupiedPercentage ?? null,
              individualOwnershipAboveTwentyFive:
                condoEligibilityFormData?.individualOwnershipAboveTwentyFive ??
                null,
              masterAssurancePolicy:
                condoEligibilityFormData?.masterAssurancePolicy ?? null,
              projectHasMobileHomes:
                condoEligibilityFormData?.projectHasMobileHomes ?? null,
              otherSourcesIncomeMoreThanTwenty:
                condoEligibilityFormData?.otherSourcesIncomeMoreThanTwenty ??
                null,
              commercialPurposesAreaMoreThanForty:
                condoEligibilityFormData?.commercialPurposesAreaMoreThanForty ??
                null,
              restrictOwnerToRent:
                condoEligibilityFormData?.restrictOwnerToRent ?? null,
              documentsWithSEC:
                condoEligibilityFormData?.documentsWithSEC ?? null,
              hoawithLitOrBankruptcy:
                condoEligibilityFormData?.hoawithLitOrBankruptcy ?? null,
              ownerControlStartDate:
                condoEligibilityFormData?.ownerControlStartDate
                  ? getFormattedDate(
                    condoEligibilityFormData?.ownerControlStartDate
                  )
                  : null,
              indivdualOwnedUnits:
                condoEligibilityFormData?.indivdualOwnedUnits ?? null,
              hoawithLitOrBankruptcyDesc:
                condoEligibilityFormData?.hoawithLitOrBankruptcyDesc ?? null,
              restrictOwnerToRentOutDesc:
                condoEligibilityFormData?.restrictOwnerToRentOutDesc ?? null,
              condoProjectName:
                condoEligibilityFormData?.condoProjectName ?? null,
              streetAddressLine1:
                condoEligibilityFormData?.condoStreetAddress ?? null,
              noOfUnits: condoEligibilityFormData?.unit ?? null,
              state: condoEligibilityFormData?.state ?? null,
              city: condoEligibilityFormData?.city ?? null,
              postalCode: condoEligibilityFormData?.zip ?? null,
            },
          },
        },
        templateKeys: ["rule.InvestorDSCR.condominium"],
      };

      const latestVersioningDate = "12-11-2024";
      const { data } = await postCalculateEligibility(reqBody, realmHeaders);
      const condoValue = data[`rule.InvestorDSCR.condominium`];
      const isNew: any = moment(latestVersioningDate, "DD-MM-YYYY").isSameOrBefore(moment(loansApiResponse?.createdAt))
      let condoValueDisplay = "";
      switch (condoValue) {
        case "Doesn't meet TRK Min Requirements": {
          condoValueDisplay = "Not Eligible";
          break;
        }
        case "Meets TRK Standard Requirements": {
          condoValueDisplay = isNew ? "Warrantable" : "Standard";
          break;
        }
        case "Only Meets TRK Min Requirements": {
          condoValueDisplay = isNew ? "Non-Warrantable" : "Min";
          break;
        }
        default:
      }
      setLoanDetails({
        ...loanDetails,
        ["loanInformation"]: {
          ...loanDetails.loanInformation,
          condoEligibility: condoValueDisplay,
        },
        loanCondominium: reqBody?.data?.loan?.loanCondominium,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setCondoEligibility(false);
    }
  }

  function handleCondoBtnClick(button: any) {
    if (button?.type === "contained") {
      calculateEligibility();
    } else if (button?.label === "download") {
      const tempLoanDetails = {
        ...loanDetails,
        loanCondo: condoEligibilityFormData,
      };
      exportHTML({ loanDetails: tempLoanDetails, loanId, loanType });
    } else {
      setCondoEligibility(false);
    }
  }

  function CondoEligibiltyModalContent() {
    const config = preLoadCondoEligibleConfig();
    const configKeys = Object.keys(config.fields);
    return (
      <Grid container className="p-12">
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
          className="mb-8"
        >
          <span>{templateLabels?.["toorakCondominimumQuestionnaire"]}</span>
          <IconButton
            onClick={() => setCondoEligibility(false)}
            data-testid="condo-close-icon"
          >
            <img src={CloseIcon} />
          </IconButton>
        </Grid>
        {configKeys.map((key: string, idx: number) => {
          return (
            <Grid container key={idx} className="mb-8">
              {condoEligibilityConfig?.fields[key]
                ?.filter((ele: any) => ele.isVisible)
                .map((config: ObjectType, i: number) => {
                  return (
                    <Grid
                      item
                      xs={config?.columnSplit}
                      key={i}
                      className="mb-8 mt-12"
                    >
                      {renderFieldUI(
                        config,
                        i,
                        condoEligibilityFormData?.[config.label],
                        handleCondoEligibilityModalChange,
                        loanType,
                        theme,
                        isApplicationSubmitted,
                        t,
                        loanContextData,
                        ""
                      )}
                    </Grid>
                  );
                })}
              <Grid item xs={12} className="mb-8 mt-12">
                <Divider />
              </Grid>
            </Grid>
          );
        })}
        <Divider />
        {condoEligibilityConfig.buttons.map((button: any, index: number) => {
          return (
            <Grid item xs={button.gridSize} key={index}>
              <Button
                variant={button.type}
                onClick={() => handleCondoBtnClick(button)}
                data-testid={button?.label}
              >
                {t(button?.label)}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  function renderSubmitApplicationModal() {
    return (
      <Grid sx={{ padding: "24px" }}>
        {(noAssignedPartner === "eligibilityAndPricingText" || isError) ? <div>
          <p style={{
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "30px",
          }}>{t("unableToSubmit")}</p>
          <p style={{
            color: "#3C3C3C",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            textAlign: "left"
          }}>{t("submitValidationErrorMessage")}</p>
          <LoadingButton size="large" variant="contained" loading={isLoading} onClick={() => setOpenWarningBeforeSubmit(false)} style={{ marginTop: "16px" }}>Ok</LoadingButton>
        </div> : <div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
            <img src={WarnigIconYellow} />
            <span style={{ fontWeight: 600, fontSize: "18px", margin: "0px 8px" }}>{t("wait ")}</span>
            <img src={WarnigIconYellow} />
          </div>
          <div>
            <span style={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "22px",
              textAlign: "center",
              color: "#3C3C3C"
            }}>{t("submitConfirmationBody1")}</span>
            <span style={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "22px",
              textAlign: "center",
              color: "#3C3C3C"
            }}>{t("submitConfirmationBody2")}</span>
            <span style={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "22px",
              textAlign: "center",
              color: "#3C3C3C"
            }}>{t("submitConfirmationBody3")}</span>
          </div>
          {loanType === "InvestorDSCR" ? <div style={{ backgroundColor: "#F8F9FD", display: "flex", justifyContent: "space-between", padding: "8px 10px", borderRadius: "6px", margin: "24px 0px 32px 0px" }}>
            <span style={{
              color: "#40128B",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0.5px",
              textAlign: "left"
            }}>Program Selected:</span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.5px",
                textAlign: "left",
                color: "#1C1C1C"
              }}
            >
              {DSCROrgPartnerMapping[assignedOrTakeOutPartnerId]}
            </span>
          </div> : null}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "16px 0px" }}>
            <LoadingButton fullWidth size="large" variant="contained" loading={isLoading} onClick={() => handleSubmitApplication()}>Yes, Submit</LoadingButton>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <LoadingButton fullWidth size="large" variant="outlined" loading={isLoading} onClick={() => setOpenWarningBeforeSubmit(false)}>Go Back</LoadingButton>
          </div>
        </div>}
      </Grid>
    )
  }

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackBar(false);
    setSnackBarMsg("");
  };

  const renderSnackBar = () => {
    const style =
      snackBarMsg === "loanSavedFailed" ? errorSnackBar : snackbarStyle;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{ style }}
        message={
          <div className="flex-display">
            <img
              src={
                snackBarMsg === "loanSavedFailed"
                  ? InfoErrorIcon
                  : CheckedCircle
              }
              className="mr-12"
            />
            <span>
              {t(
                snackBarMsg === "loanSavedFailed"
                  ? "loanSavedFailed"
                  : t(snackBarMsg).replace("replace", tcLoanId)
              )}
            </span>
          </div>
        }
      />
    );
  };

  const fetchAddress = useCallback(
    debounce(async (request: { input: string }) => {
      try {
        const { data } = await getAutocompleteAddress(
          dynamicConfig?.ViteAppSmartyStreetsApiKey,
          request.input
        );
        setAddressOptions([...addressOptions, ...data.suggestions]);
      } catch (err) {
        console.error(err);
      }
    }, 200),
    []
  );

  const fetchCityState = useCallback(
    debounce(async (request: { input: string }) => {
      try {
        const { data } = await getZipCodeList(
          dynamicConfig?.ViteAppSmartyStreetsApiKey,
          request.input
        );
        const { city_states, zipcodes } = data[0];
        let newOptions = [] as ZipCodeSuggestion[];
        if (city_states && zipcodes) {
          const zipCode = zipcodes[0].zipcode;
          const temp = city_states.map((ele: ObjectType) => {
            return { ...ele, ...{ zipCode } };
          });
          newOptions = [...newOptions, ...temp];
        }
        setZipCodeOptions(newOptions);
      } catch (err) {
        console.error(err);
      }
    }, 200),
    []
  );

  const expandTabs = () => {
    setExpandLeverageAndTests(!expandLeverageAndTests);
  };

  async function getConfigForLoan(data: any, createdAtDate: any) {
    const tabName = currentTab;
    let config: any = {};
    let lpConfig: any = {};
    let type: string = "Bridge";

    switch (loanType) {
      case "InvestorDSCR":
        config = getConfig("DSCR", defaultSelectedDataView, templateLabels, createdAtDate);
        switch (tabName) {
          case 0:
            lpConfig = getLeveragePricingTestsConfig("DSCR", 0);
            break;
          case 1:
            lpConfig = getLeveragePricingTestsConfig("DSCR", 1);
            break;
        }
        break;
      case "BridgeLoan":
        if (
          data?.propertyType === "Multifamily/MixedUse" &&
          rehabTypes.includes(propType as string)
        ) {
          type += "MultiFamily";
        } else if (propType === "GroundUp") {
          type += "GroundUp";
        } else if (propType === "Fix&Flip") {
          type += "Fix&Flip";
        } else if (propType === "Stabilized") {
          type += "Stabilized";
        } else {
          type += "Rehab"
        }

        config = getConfig(
          type,
          defaultSelectedDataView,
          templateLabels,
          data.createdAt,
          propType
        );
        switch (tabName) {
          case 0:
            lpConfig = getLeveragePricingTestsConfig(type, 0, propType, userPermissions);
            break;
          case 1:
            lpConfig = getLeveragePricingTestsConfig(type, 1, propType, userPermissions);
            break;
        }
        break;
    }

    let tempLoanDetailsConfig: ObjectType = {};
    let tempPropertyDetailsConfig: ObjectType[] = [];
    let tempBorrowerDetailsConfig: ObjectType = {};
    let tempAutoCalculatedFields: ObjectType = {};

    if (config) {
      config?.components?.forEach((ele: ObjectType) => {
        switch (ele.label) {
          case "loanDetails":
            tempLoanDetailsConfig = ele;
            break;
          case "borrowerDetails":
            tempBorrowerDetailsConfig = ele;
            break;
          case "propertyDetails":
            tempPropertyDetailsConfig = [ele];
            break;
          default:
            tempAutoCalculatedFields = ele;
            break;
        }
      });
    }

    let unitTemplate: ObjectType = {};
    tempPropertyDetailsConfig[0]?.fields?.map((field: ObjectType) => {
      if (Array.isArray(field)) {
        unitTemplate = field[0];
      }
    });
    const propTemplate = { ...(tempPropertyDetailsConfig?.[0] ?? {}) };
    const tempPropConfig = [...tempPropertyDetailsConfig];
    let tempNewAutoCalculatedConfig = { ...tempAutoCalculatedFields };

    data?.propertyDetails?.map((propDetail: ObjectType, idx: number) => {
      let currentPropConfig = {
        ...(tempPropertyDetailsConfig[idx] ??
          tempPropertyDetailsConfig?.[0] ??
          {}),
      };
      tempNewAutoCalculatedConfig = {
        ...tempAutoCalculatedFields,
        fields: tempAutoCalculatedFields?.fields?.map((config: ObjectType) => {
          if (Array.isArray(config)) {
            if (config?.length !== data?.autoCalculatedPropResults?.length) {
              config.push(config[0]);
              return config;
            }
            return config;
          }
          return config;
        })
      };

      propDetail?.unitInformation?.map(
        (unitInfo: ObjectType, unitIdx: number) => {
          if (
            unitIdx !== 0 &&
            unitIdx <= propDetail?.unitInformation.length - 1
          ) {
            currentPropConfig = {
              ...currentPropConfig,
              fields: currentPropConfig.fields?.map(
                (field: ObjectType | ObjectType[]) => {
                  if (Array.isArray(field)) {
                    return [...field, unitTemplate];
                  }
                  return field;
                }
              ),
            };
          }
        }
      );
      tempPropConfig[idx] = currentPropConfig;
    });

    return {
      unitTemplate,
      tempLoanDetailsConfig,
      tempPropertyDetailsConfig: tempPropConfig,
      tempBorrowerDetailsConfig,
      tempAutoCalculatedFields: tempNewAutoCalculatedConfig,
      config,
      lpConfig,
      propTemplate,
    };
  }

  async function getLoanDetailsFromConfig(
    tempLoanDetailsConfig: ObjectType,
    tempPropertyDetailsConfig: ObjectType,
    data: ObjectType
  ) {
    const tempLoanDetails: ObjectType = {};
    let tempPropertyDetails: ObjectType = {};

    tempLoanDetailsConfig.fields?.forEach((field: ObjectType) => {
      const tempSubLoanData: any = {};
      field?.fields
        // ?.filter((ele: ObjectType) => {
        //   if (defaultSelectedDataView === "fullDataView") {
        //     return ele;
        //   }
        //   return ele[defaultSelectedDataView];
        // })
        ?.map((subField: ObjectType) => {
          if (subField.label === "includeOutOfPocketBudgetARLTV") {
            tempSubLoanData[subField.label] = true;
          } else if (subField.label === "decliningMarkets") {
            tempSubLoanData[subField.label] = "No";
          } else if (subField.label === "totalRehabBudget") {
            tempSubLoanData[subField.label] = "0";
          } else if (subField.label === "financedBudget") {
            tempSubLoanData[subField.label] = "0";
          } else if (
            data?.loanType === "Fix & Flip" &&
            subField.label === "loanStructure"
          ) {
            tempSubLoanData[subField.label] = "Multiple Draws";
          } else if (
            data?.loanType === "Stabilized" &&
            subField.label === "loanStructure"
          ) {
            tempSubLoanData[subField.label] =
              "Single Draw (No Rehab Escrow)";
          } else if (
            subField.label === "loanTerm" &&
            loanType === "InvestorDSCR"
          ) {
            tempSubLoanData[subField.label] = "360";
          } else if (subField.label === "rateType") {
            tempSubLoanData[subField.label] = "Fixed";
          } else if (subField.label === "creditEvent") {
            tempSubLoanData[subField.label] = "No";
          } else if (
            loanType === "BridgeLoan" &&
            data?.loanType === "Stabilized" &&
            data?.propertyType === "Multifamily/MixedUse" &&
            subField.label === "exitDebt"
          ) {
            subField.isVisible = false;
          } else {
            tempSubLoanData[subField.label] = "";
          }
          return subField;
        });
      tempLoanDetails[field.label] = tempSubLoanData;
    });

    tempPropertyDetailsConfig?.forEach((propDetails: ObjectType) => {
      propDetails?.fields?.map((field: ObjectType) => {
        const tempSubLoanData: any = {};
        if (Array.isArray(field)) {
          field[0]?.fields
            // ?.filter((ele: ObjectType) => {
            //   if (defaultSelectedDataView === "fullDataView") {
            //     return ele;
            //   }
            //   return ele[defaultSelectedDataView];
            // })
            ?.map((subField: ObjectType) => {
              if (subField.label === "includeOutOfPocketBudgetARLTV") {
                tempSubLoanData[subField.label] = true;
              } else if (subField.label === "decliningMarkets") {
                tempSubLoanData[subField.label] = "No";
              } else if (
                ["Condo", "Single Family/Detached"].includes(
                  data?.propertyType
                ) &&
                subField.label === "numberOfUnits"
              ) {
                subField.isVisible = false;
              } else {
                tempSubLoanData[subField.label] = "";
                subField.isVisible =
                  subField.label === "numberOfUnits"
                    ? true
                    : subField.isVisible;
              }
              return subField;
            });
          tempPropertyDetails[field[0]?.label] = [tempSubLoanData];
        } else {
          field?.fields
            // ?.filter((ele: ObjectType) => {
            //   if (defaultSelectedDataView === "fullDataView") {
            //     return ele;
            //   }
            //   return ele[defaultSelectedDataView];
            // })
            ?.map((subField: ObjectType) => {
              if (subField.label === "includeOutOfPocketBudgetARLTV") {
                tempSubLoanData[subField.label] = true;
              } else if (subField.label === "decliningMarkets") {
                tempSubLoanData[subField.label] = "No";
              } else if (
                ["Condo", "Single Family/Detached"].includes(
                  data?.propertyType
                ) &&
                subField.label === "numberOfUnits"
              ) {
                subField.isVisible = false;
              } else if (subField.label === "estimatedRentPerYearUponCompletion") {
                subField.isVisible = data?.loanInformation?.exitStrategy === "Rehab to Hold/Rent" ||
                  (loanType === "BridgeLoan" && data?.loanType && ["Fix & Flip", "Rehab", "Stabilized"].includes(data.loanType))
              } else if (subField.label === "grossPotentialRentMonthly") {
                subField.isVisible = data?.loanInformation?.exitStrategy === "Build to Rent"
              } else {
                tempSubLoanData[subField.label] = "";
                subField.isVisible =
                  subField.label === "numberOfUnits"
                    ? true
                    : subField.isVisible;
              }
              return subField;
            });
          tempPropertyDetails[field.label] = tempSubLoanData;
        }
      });
    });

    const tempUnitInformation =
      tempPropertyDetails?.unitInformation ??
      (data?.propertyDetails?.length
        ? data?.propertyDetails[0]?.unitInformation?.map((ele: ObjectType) => {
          return {
            [Object.keys(ele)[0]]: "",
          };
        })
        : {});
    tempPropertyDetails.propertyInformation = {
      ...tempPropertyDetails.propertyInformation,
      propertyType:
        data?.propertyType === "Single Family/Detached"
          ? "SFR"
          : data?.propertyType === "Multifamily/MixedUse"
            ? ""
            : data?.propertyType,
    };
    tempPropertyDetails = [tempPropertyDetails];
    return { tempUnitInformation, tempLoanDetails, tempPropertyDetails };
  }

  const getLoanDataBasedOnLoanId = async () => {
    setDynamicLoader(true);
    try {
      // const url = getURL("online-sizer/fetch");
      const body = [loanId];
      const { data } = await loanDataBasedOnLoanId(body);
      const {
        unitTemplate,
        tempLoanDetailsConfig,
        tempBorrowerDetailsConfig,
        tempPropertyDetailsConfig,
        tempAutoCalculatedFields,
        config,
        lpConfig,
        propTemplate,
      } = await getConfigForLoan(data?.data["0"]?.data, data?.data["0"]?.createdAt);
      const { tempUnitInformation, tempLoanDetails, tempPropertyDetails } =
        await getLoanDetailsFromConfig(
          tempLoanDetailsConfig,
          tempPropertyDetailsConfig,
          data?.data["0"]?.data
        );

      const propConfig = [...tempPropertyDetailsConfig];
      const loanDetails = data?.data["0"]?.data;
      const indexOfMostRestrictiveProperty = loanDetails?.autoCalculatedLoanResults?.loanCharacterisation?.indexOfMostRestrictiveProperty ?? 0
      const propertyType = loanDetails?.propertyDetails?.[indexOfMostRestrictiveProperty]?.propertyInformation?.propertyType ?? loanDetails?.propertyType
      const tempChipData: string[] = [
        loanDetails?.productType,
        propertyType.includes("Mixed")
          ? t("multiFamilyMixedUseLabel")
          : propertyType,
      ];

      if (loanDetails?.productType.includes("Bridge")) {
        tempChipData.push(loanDetails?.loanType);
      }

      const takeOutPartnerId = loanDetails?.takeOutPartner || assignedPartner;

      if (
        loanType === "InvestorDSCR" && DSCROrgPartnerMapping[takeOutPartnerId] &&
        !tempChipData.includes(DSCROrgPartnerMapping[takeOutPartnerId])
      ) {
        tempChipData.push(DSCROrgPartnerMapping[takeOutPartnerId]);
      }

      let tempLoanData = { ...loanDetails };
      if (
        tempLoanData?.loanInformation &&
        !Object.keys(tempLoanData?.loanInformation)?.includes("creditEvent")
      ) {
        tempLoanData = {
          ...tempLoanData,
          loanInformation: {
            ...tempLoanData.loanInformation,
            creditEvent: "No",
          },
        };
      }
      if (!Object.keys(tempLoanData).includes("propertyDetails")) {
        tempLoanData = { ...tempLoanDetails };
      }
      const tempPropData = loanDetails?.propertyDetails || tempPropertyDetails;
      let tempIsExpanded = { ...isExpanded, ["Loan Details"]: true };
      delete tempLoanData?.propertyDetails;

      const tempBorrowerInfoConfigDynamic = {
        ...tempBorrowerDetailsConfig,
        fields: tempBorrowerDetailsConfig?.fields?.map(
          (subComponent: ObjectType) => {
            const subComponentFields = subComponent.fields.map(
              (fieldObj: ObjectType) => {
                if (
                  fieldObj.label === "immigrationStatus" &&
                  tempLoanData?.borrowerInformation?.borrowerGuarantor === "Yes"
                ) {
                  fieldObj.minimumDataView = true;
                  fieldObj.isVisible = true;
                }
                if (fieldObj.label === "creditScoreActual") {
                  fieldObj.required =
                    tempLoanData?.borrowerInformation?.immigrationStatus !==
                      "Foriegn National"
                      ? true
                      : false;
                }
                if (
                  ["creditScoreActual", "originalCreditScore"].includes(
                    fieldObj.label
                  )
                ) {
                  fieldObj.required =
                    tempLoanData?.borrowerInformation?.immigrationStatus !==
                      "Foriegn National"
                      ? true
                      : false;
                }
                return fieldObj;
              }
            );
            return {
              ...subComponent,
              fields: subComponentFields,
            };
          }
        ),
      };

      const tempLoanDetailsConfigDynamic = {
        ...tempLoanDetailsConfig,
        fields: tempLoanDetailsConfig?.fields?.map(
          (subComponent: ObjectType) => {
            const subComponentFields = subComponent.fields.map(
              (fieldObj: ObjectType) => {
                if (
                  fieldObj.label === "ioPeriod" &&
                  tempLoanData?.loanEconomics?.interestOnlyFlag === "Yes"
                ) {
                  fieldObj.minimumDataView = true;
                  fieldObj.isVisible = true;
                }
                if (fieldObj.label === "includeOutOfPocketBudgetARLTV" &&
                  +tempLoanData?.loanEconomics?.totalRehabBudget !== +tempLoanData?.loanEconomics?.financedBudget) {
                  fieldObj.minimumDataView = true;
                  fieldObj.isVisible = true;
                }
                if (
                  fieldObj.label === "requestedLTPP" &&
                  tempLoanData?.loanInformation?.loanPurpose === "Purchase"
                ) {
                  fieldObj.minimumDataView = true;
                  fieldObj.isVisible = true;
                }
                return fieldObj;
              }
            );
            return {
              ...subComponent,
              fields: subComponentFields,
            };
          }
        ),
      };

      const tempPropertyDetailsConfigDynamic = propConfig.map(
        (propConfigEle: ObjectType, propIdx: number) => {
          const tempPropConfig = propConfigEle?.fields?.map(
            (propField: any) => {
              if (Array.isArray(propField)) {
                const tempPropField = propField.map(
                  (unitInfo: ObjectType, unitInfoIdx: number) => {
                    const unitInfoArr = unitInfo.fields.map(
                      (unitField: ObjectType) => {
                        const value = tempPropData[propIdx]?.unitInformation
                          ? tempPropData[propIdx]?.unitInformation[unitInfoIdx]
                            ?.currentLeaseTermsInMonths
                          : "";
                        const tempUnitField = { ...unitField };
                        const isNotValid = value === "";
                        const isZero = +value === 0;
                        const isInRange1to5 = +value >= 1 && +value <= 5;
                        const dynamicFields = [
                          "currentUnitUsage",
                          "rentalCharacterization",
                          "monthlyMarketRent",
                          "monthlyUnitRent",
                          "tenantOccupancy",
                          "operatingHistory",
                        ];
                        dynamicFields.forEach((fieldStr) => {
                          if (fieldStr === tempUnitField.label) {
                            if (
                              (isZero &&
                                [
                                  "currentUnitUsage",
                                  "rentalCharacterization",
                                  "monthlyMarketRent",
                                  "monthlyUnitRent",
                                ].includes(fieldStr)) ||
                              (isInRange1to5 &&
                                [
                                  "rentalCharacterization",
                                  "monthlyMarketRent",
                                  "monthlyUnitRent",
                                  "tenantOccupancy",
                                  "operatingHistory",
                                ].includes(fieldStr)) ||
                              (value &&
                                value > 5 &&
                                [
                                  "rentalCharacterization",
                                  "monthlyMarketRent",
                                  "monthlyUnitRent",
                                ].includes(fieldStr))
                            ) {
                              tempUnitField.minimumDataView =
                                defaultSelectedDataView === "fullDataView";
                            } else {
                              tempUnitField.minimumDataView =
                                defaultSelectedDataView !== "fullDataView";
                            }
                            tempUnitField.isVisible =
                              tempUnitField.minimumDataView;
                          }
                        });
                        return tempUnitField;
                      }
                    );
                    return {
                      ...unitInfo,
                      fields: unitInfoArr,
                    };
                  }
                );
                return tempPropField;
              }
              return propField;
            }
          );
          return { ...propConfigEle, fields: tempPropConfig };
        }
      );

      tempPropertyDetailsConfigDynamic.forEach(
        (config: ObjectType, idx: number) => {
          let label = `Property #${idx + 1}`;
          if (tempPropData[idx]?.propertyLocation?.address) {
            label = tempPropData[idx]?.propertyLocation?.address;
          }
          tempIsExpanded = {
            ...tempIsExpanded,
            [label]: true,
          };
        }
      );

      setAssignedPartner(data?.data["0"]?.data?.takeOutPartner);
      setUnitInfoConfigTemplate(unitTemplate);
      setPropertyDetailsConfigTemplate(propTemplate);
      setLoanDetailsConfig(tempLoanDetailsConfigDynamic);
      setBorrowerInformationConfig(tempBorrowerInfoConfigDynamic)
      setAutoCalculatedFields(tempAutoCalculatedFields);
      setCurrentConfig(config);
      setLeverageTestsConfig(lpConfig?.components);
      setIsExpanded({ ...isExpanded, ...tempIsExpanded });
      setChipsData(tempChipData);
      setEvaluationID(loanDetails?.evaluationID);
      setApplicationName(loanDetails?.applicationName);
      setPropertyDetailsConfig(tempPropertyDetailsConfigDynamic);
      // setLoanData(tempLoanData);
      setLoanDetails(tempLoanData);
      setPropertyDetails(tempPropData);
      setShowLoanDetails(true);
      setDefaultSelectedDataView(loanType === "InvestorDSCR" ? "minimumDataView" : "fullDataView");
      setUnitInfoTemplate(tempUnitInformation);
      setPropertyDetailsTemplate(tempPropertyDetails[0]);
      // setLoanDetails(tempLoanDetails);
      setIsApplicationSubmitted(
        Boolean(data?.data["0"]?.data?.isApplicationSubmitted)
      );
      setFetchData(data?.data["0"]?.data || {});
      const condoData = {
        ...(data?.data["0"]?.data?.loanCondominium ?? sampleCondoEligibility),
        ["borrowerName"]: decodedTokenData?.name,
      };
      setCondoEligibilityFormData(condoData);
      setTestTabData(data?.data["0"]?.data?.testTabData ?? []);
      setLeverageAndPricingData(
        data?.data["0"]?.data?.leverageAndPricingData ?? {}
      );
      setAutoCalculatedLoanResults(
        data?.data["0"]?.data?.autoCalculatedLoanResults ?? {}
      );
      setAutoCalculatedPropResults(
        data?.data["0"]?.data?.autoCalculatedPropResults ?? []
      );
      setRulesResponse(data?.data?.["0"]?.rulesResponse);
      setLoansApiResponse(data?.data["0"]);
      setEligiblePartnerResults(data?.data["0"]?.data?.eligiblePartnerResults ?? {});
      setPartnersResults(data?.data["0"]?.data?.partnersResults ?? {});
      postGuidelineEvaluation(setDebouncedValue, {
        tempLoanData,
        tempPropData,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
    }
  };

  const handleShowTermSheetBanner = (status: boolean) => {
    setShowTermSheetBanner(status)
  }

  return (
    <>
      {showTermSheetBanner ? <TermSheetBanner handleShowTermSheetBanner={handleShowTermSheetBanner} toorakSupport={dynamicConfig?.supportEmail}/> : null}
      <Header
        showSubmitLoanBtn={true}
        showSaveApplicationBtn={true}
        showBackToListBtn={true}
        showLinkBtn={true}
        showDownloadBtn={true}
        showBorder={true}
        validateForm={validateForm}
        handleSaveApplication={handleSaveApplication}
        setApplicationName={setApplicationName}
        applicationName={applicationName}
        isLoading={isLoading}
        openSaveApplicationModal={openSaveApplicationModal}
        setOpenSaveApplicationModal={setOpenSaveApplicationModal}
        evaluationID={evaluationID}
        handleSubmitApplication={handlePromptSubmit}
        disableSubmitApplication={disableSubmitApplication}
        disableSaveApplication={isApplicationSubmitted}
        applicationError={applicationError}
        setApplicationError={setApplicationError}
        showCreateTermSheet={showTermSheet}
        showKDMButton={showKeyDealMetrics}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          sm={expandAvailableProgramsView ? 0 : !expandLeverageAndTests ? 7 : 2}
          style={!expandAvailableProgramsView ? { "display": "block", borderRight: "1px solid #9FAAA9", padding: "12px" } : { "display": "none", borderRight: "1px solid #9FAAA9", padding: "12px" }}
        >
          {(noAssignedPartner && !expandAvailableProgramsView) ? (
            <Disclaimer
              boldText={t("")}
              children={
                <span className="warning-div">
                  <img src={WarningIcon} alt="warning,svg" />
                  &ensp;
                  {t(noAssignedPartner)}
                </span>
              }
              customClass="warningMessage"
              showClose={false}
            />
          ) : null}
          {!(expandLeverageAndTests && expandAvailableProgramsView) && (
            <Disclaimer
              boldText={t("disclaimer")}
              children={disclaimer}
              customClass="disclaimerBox"
              showClose={true}
            />
          )}
          {!(expandLeverageAndTests && expandAvailableProgramsView) && (
            <>
              {showLoanDetails ? (
                <div className="div-space-between mb-20">
                  <div>
                    <span>
                      <b>{t("applicationName")}: </b>
                      {applicationName}
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>
                        {t("evaluationID")}: {evaluationID}
                      </b>
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          )}
          <div className="div-space-between">
            <Stack
              direction="row"
              className="mt-12"
              style={{ flexWrap: expandLeverageAndTests ? "wrap" : "nowrap" }}
            >
              {chipsData?.map((chip: string, idx: number) => {
                return (
                  <CustomChip
                    key={idx}
                    size="small"
                    label={chip}
                    variant="outlined"
                    className="chipData"
                  />
                );
              })}
            </Stack>
            {!(expandLeverageAndTests && expandAvailableProgramsView) && (
              <>
                {currentConfig?.dataView?.filter((view: ObjectType) =>
                  view.isVisible(loanType)
                ).length ? (
                  <div>
                    <CustomButtonGroup
                      buttons={currentConfig.dataView}
                      selectedButton={defaultSelectedDataView}
                      handleDataViewChange={handleDataViewChange}
                      className=""
                      buttonSize=""
                      translation={t}
                      loanContextData={loanContextData}
                      component={undefined}
                      isDataView={true}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>

          {!(expandLeverageAndTests && expandAvailableProgramsView) && <BorrowerInformation
            isApplicationSubmitted={isApplicationSubmitted}
            idx={0}
            loanType={loanType}
            theme={theme}
            translation={t}
            loanId={loanId}
            propType={propType}
            loanContextData={loanContextData}
          />}
          {!(expandLeverageAndTests && expandAvailableProgramsView) && propertyDetailsConfig?.map((propConfig: ObjectType, idx: number) => {
            return (
              <PropertyDetails
                isApplicationSubmitted={isApplicationSubmitted}
                idx={idx}
                loanType={loanType}
                theme={theme}
                translation={t}
                loanId={loanId}
                propType={propType}
                propConfig={propConfig}
                label={
                  propertyDetails[idx]?.propertyLocation?.address ||
                  `Property #${idx + 1}`
                }
                loanContextData={loanContextData}
              />
              /*<AccordionUI
                component={propConfig}
                idx={idx}
                label={
                  propertyDetails[idx]?.propertyLocation?.address ||
                  `Property #${idx + 1}`
                }
                data={propertyDetails}
                expandLeverageAndTests={expandLeverageAndTests}
                isApplicationSubmitted={isApplicationSubmitted}
                propertyDetails={propertyDetails}
                propertyDetailsConfig={propertyDetailsConfig}
                propertyDetailTemplate={propertyDetailTemplate}
                propertyDetailsConfigTemplate={propertyDetailsConfigTemplate}
                unitInfoConfigTemplate={unitInfoConfigTemplate}
                unitInfoTemplate={unitInfoTemplate}
                setPropertyDetails={setPropertyDetails}
                setPropertyDetailsConfig={setPropertyDetailsConfig}
                handleIconButtonClick={handleIconButtonClick}
                checkIconButtonDisabled={checkIconButtonDisabled}
              />*/
            );
          })}
          {!(expandLeverageAndTests && expandAvailableProgramsView) && <LoanDetails
            isApplicationSubmitted={isApplicationSubmitted}
            idx={0}
            loanType={loanType}
            theme={theme}
            translation={t}
            loanId={loanId}
            propType={propType}
            loanContextData={loanContextData}
          />}
          {!(expandLeverageAndTests && expandAvailableProgramsView) &&
            <AutoCalculatedFields
              isApplicationSubmitted={isApplicationSubmitted}
              idx={0}
              loanType={loanType}
              theme={theme}
              translation={t}
              loanId={loanId}
              propType={propType}
              loanContextData={loanContextData}
            />
          }
          {(expandLeverageAndTests && expandAvailableProgramsView) && <ExpandedFieldsUI loanType={loanType} loanContextData={loanContextData} theme={theme} isApplicationSubmitted={isApplicationSubmitted} />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={(expandLeverageAndTests) ? 10 : !expandAvailableProgramsView ? 5 : 12}
          style={{ padding: 12, background: "#F4F6FA" }}
        >
          <div className="div-space-between align-flex-start border-bottom-divider">
            <Tabs value={currentTab} onChange={handleTabChange}>
              {TabLists.map((tab: any, idx: number) => {
                if (tab.isVisible({ loanType, fundingType: orgFundingType, allowPartnerSelection: dynamicConfig?.allowPartnerSelection })) {
                  const Icon = tab.icon;
                  return (
                    <Tab
                      key={idx}
                      label={t(tab.label)}
                      value={idx}
                      icon={<img src={Icon} alt="icon" />}
                      iconPosition="start"
                    />
                  );
                }
                return null;
              })}
            </Tabs>
            <div className="div-space-between align-flex-start">
              {currentTab === 2 ? <Stack
                direction="row"
                className="mt-12"
                style={{ flexWrap: expandLeverageAndTests ? "wrap" : "nowrap" }}
              >
                {chipsData?.map((chip: string, idx: number) => {
                  return (
                    <CustomChip
                      key={idx}
                      size="small"
                      label={chip}
                      variant="outlined"
                      className="chipData"
                    />
                  );
                })}
              </Stack>
                : null}
              {/* TODO: uncomment this to enable what if scenarios */}
              <IconButton onClick={expandTabs}>
                <img
                  src={expandLeverageAndTests ? ShrinkButton : ExpandButton}
                  alt="expand-button"
                />
              </IconButton>
            </div>
          </div>
          <TabPanel value={currentTab} index={0}>
            {
              assignedOrTakeOutPartnerId && loanType === "InvestorDSCR" && (dynamicConfig?.allowPartnerSelection && orgFundingType !== "table_funding") ?
                <div>
                  <Disclaimer
                    boldText={""}
                    children={<span>{t("bestPricingForTheApplication")}</span>}
                    customClass="disclaimerBox"
                    showClose={true}
                    isRatesAsOff={false}
                  />
                  <div style={{ backgroundColor: "#6750A4", color: "white", display: "flex", justifyContent: "space-between", padding: "8px 10px", borderRadius: "6px", marginTop: "8px" }}>
                    <span style={{ fontWeight: 500 }}>Program Selected:</span>
                    <span style={{ fontWeight: 600 }}>{DSCROrgPartnerMapping[assignedOrTakeOutPartnerId]}</span>
                  </div>
                </div>
                : null
            }
            <Grid container spacing={2} className="mt-12">
              {failedFinalRate ? (
                <div className="p-12">
                  <div className="finalPrice-err-msg p-12 div-space-between">
                    <img src={InfoErrorIcon} alt="info-svg" className="mr-12" />
                    <p className="fs-14">{t("finalPriceCalclationError")}</p>
                  </div>
                </div>
              ) : null}
              {isRiskPricing && <div className="p-12 d-flex w-100">
                <div className="risk-price-msg p-12">
                  <img src={InfoMsgIcon} alt="info-svg" className="mr-12 h-20" />
                  <div className="fs-14">{t(isRiskPricing)}</div>
                </div>
              </div>}
              {renderRightPanel()}
            </Grid>
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <BridgeLoanResultTab
              tabData={testTabData}
              isExpanded={isExpanded}
              expandLeverageAndTests={expandLeverageAndTests}
              setIsExpanded={setIsExpanded}
              loanDetails={loanDetails}
              isLoanDSCR={loanType === "InvestorDSCR"}
              ratesAsOfDate={ratesAsOfDate}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <PartnerSelectionView setSnackBarMsg={setSnackBarMsg} setShowSnackBar={setShowSnackBar} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
          </TabPanel>
        </Grid>
        {showSnackBar ? renderSnackBar() : null}
      </Grid>
      {condoEligibility ? (
        <CustomModal
          open={condoEligibility}
          handleClose={() => setCondoEligibility(false)}
          children={CondoEligibiltyModalContent()}
          className={"condoEligibility"}
        />
      ) : null}
      {openWarningBeforeSubmit ? (
        <CustomModal
          open={openWarningBeforeSubmit}
          handleClose={setOpenWarningBeforeSubmit}
          children={renderSubmitApplicationModal()}
          className={"saveApplication"}
        />
      ) : null}
    </>
  );
}
