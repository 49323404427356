import { Box, Grid } from '@mui/material'
import CloseIcon from '../../assets/Close-Icon-violet.svg'
import InfoIcon from '../../assets/InfoIconViolet.svg'

type TermSheetBannerPropTypes = {
    handleShowTermSheetBanner : (status: boolean) => void
    toorakSupport: any
}

const TermSheetBanner = (props: TermSheetBannerPropTypes ) => {
    const { handleShowTermSheetBanner, toorakSupport} = props

    return (
    <div style={{width: "100%", 
            minHeight: "40px",
            background: "#E4EAFF", 
            borderBottom: "1px solid #5503DC",
            paddingTop: "8px",
            marginBottom: "8px",
            boxShadow: "0px 4px 16px 0px #00000029",
    }}>
        <Grid container>
            <Grid item xs={11}>
                <Box sx={{fontSize: "15px", lineHeight: "22.5px", fontWeight: "500", paddingLeft: "12px"}}>
                    <span style={{position: "relative", top: "4px"}}>
                        <img src={InfoIcon} /> 
                    </span>
                    <span style={{color: "#5503DC", paddingLeft: "4px"}}>
                        New!
                    </span>
                    {" "}Explore the option to generate a white labeled term sheet. Contact us at {" "}
                    <a href={`mailto:${toorakSupport}`} style={{textDecoration: "none"}}>
                        <em style={{color: "#5503DC", fontSize: "15px"}}>{`{${toorakSupport}}`}</em> 
                    </a>{" "}to upload your logo to the termsheet. 
                </Box>
            </Grid>
            <Grid item xs={1} sx={{display: "flex", justifyContent: "end", paddingRight: "12px"}}>
                <span onClick={() => handleShowTermSheetBanner(false)} style={{cursor: "pointer"}}>
                    <img src={CloseIcon} />
                </span>
            </Grid>
        </Grid>
    </div>
  )
}

export default TermSheetBanner
